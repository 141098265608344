import { Component, BaseComponent, namespace, Prop, VariationBase } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';
import { IVSFUser, KEY as UserKey } from 'theme/@types/vsf/stores/user';
import { IVSFStoreUI, KEY as UIKey } from 'theme/@types/vsf/stores/ui';
import { IMyAccountStore, KEY as MyAccountKey } from 'theme/@types/zento/stores/myaccount';

import type { IAccount } from './Account.d';

const uiUser = namespace<IVSFUser>(UserKey);
const uiStore = namespace<IVSFStoreUI>(UIKey);
const myAccountStore = namespace<IMyAccountStore>(MyAccountKey);

export type IconName = 'account' | 'account-white';

/**
 * Account Variations
 *
 * Account Variations component allowing to show account signup in modal/drawer.
 **/
@Component({})
@VariationBase
export class Account extends BaseComponent<IAccount, unknown> {
  protected static V = {
    account1: () => import('./variations/account1/account1').then((m) => m.Account1),
    account2: () => import('./variations/account2/account2').then((m) => m.Account2),
    account3: () => import('./variations/account3/account3').then((m) => m.Account3),
  };

  protected static T = {
    titleSignUp: 'title_sign_up',
    titleAccountDrawer: 'title_account_drawer',
    signInLabel: 'sign_in_label',
    createAccountLabel: 'create_account_label',
  };

  /**
   * A text representing header item label
   */
  @Prop({ type: [Object, String], default: undefined })
  protected headerItemLabel?: I18nMessage;

  /**
   * Icon.
   */
  @Prop({ type: String, default: 'account' })
  icon?: IconName;

  /**
   * Determines if my account is opened
   */
  @myAccountStore.Getter('getAccountState')
  protected isMyAccountOpen: IMyAccountStore['state']['myaccount'];

  @myAccountStore.State('drawerActive')
  protected drawerActive: IMyAccountStore['state']['drawerActive'];

  @uiUser.State('current')
  protected currentUser: IVSFUser['state']['current'];

  @uiStore.State('authElem')
  protected activeElement: IVSFStoreUI['state']['authElem'];

  data() {
    return {
      remember: false,
      email: '',
      password: '',
      showOverlay: false,
    };
  }

  /**
   * Open my account drawer
   */
  protected toggleMyAccount() {
    this.$store.dispatch('myAccount/toggleMyAccount');
    this.$store.commit('ui/setOverlay', this.isMyAccountOpen);
  }

  /**
   * Switch my account to login
   */
  protected switchElemLogin() {
    this.$store.commit('ui/setAuthElem', 'login');
  }

  /**
   * Switch my account to register
   */
  protected switchElemRegister() {
    this.$store.commit('ui/setAuthElem', 'register');
  }
}
