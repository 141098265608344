import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';

import { Link } from '../Link/Link';

import type { ILogo } from './Logo.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

/**
 * Logo
 *
 * Logo component allowing to create logo.
 **/
@Component({})
export class Logo extends BaseComponent<ILogo, unknown> {
  /**
   * Set src for logo.
   */
  @Prop({ type: String, required: false, default: '../../assets/logo.svg' })
  src?: string;

  /**
   * Set alt name for logo.
   */
  @Prop({ type: String, default: 'Zento' })
  alt?: string;

  /**
   * Set width for logo.
   */
  @Prop({ type: Number, default: 128 })
  width?: number;

  /**
   * Set height for logo.
   */
  @Prop({ type: Number, default: 72 })
  height?: number;

  @appContextStore.Getter('isMobile')
  private isMobile: boolean;

  data() {
    return {
      logo: this.extended.$config.zento.theme.header,
      logoUrl: this.extended.$config.zento.theme.storeData.storeUrl + this.extended.$config.zento.theme.header.logoUrl,
      logoMobileUrl:
        this.extended.$config.zento.theme.storeData.storeUrl + this.extended.$config.zento.theme.header.logoMobileUrl,
    };
  }

  render() {
    return (
      <div class={style.logo} data-testId='logo'>
        <Link to='/'>
          <img
            src={
              this.isMobile && this.$data.logo.logoMobileUrl.length
                ? this.$data.logoMobileUrl
                : this.$data.logoUrl ?? this.src
            }
            alt={this.$data.logo.alt ?? this.alt}
            width={
              this.isMobile && this.$data.logo.logoMobileUrl.length
                ? this.$data.logo.mobileWidth
                : this.$data.logo.width ?? this.width
            }
            height={
              this.isMobile && this.$data.logo.logoMobileUrl.length
                ? this.$data.logo.mobileHeight
                : this.$data.logo.height ?? this.height
            }
            loading='lazy'
          />
        </Link>
      </div>
    );
  }
}
